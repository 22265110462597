import React from "react";
import { Accordion } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Skin from "./Skin";
import "../styles/tradeUp.scss";

const TradeSummary = ({ trade, isShowDelete = false, deleteTradeFromList }) => {
  const {
    id,
    avgFloat,
    tradeupCost,
    topResultPrice,
    topResultSkinId,
    oddsToProfit,
    inputSkins,
    outputSkins,
  } = trade;

  const renderTradeSkins = (skinArr) => {
    if (skinArr?.length === 0) return <></>;

    return skinArr.map((skin, i) => {
      return (
        <div className="tradeSummarySkin" key={i}>
          <Skin skinDetails={skin} isTopResult={skin.id === topResultSkinId} />
        </div>
      );
    });
  };

  const oddsToProfitPercentage = parseFloat(oddsToProfit) * 100;

  const fixedAvgFloat = parseFloat(avgFloat).toFixed(7);
  const fixedtradeupCost = `$${parseFloat(tradeupCost).toFixed(2)}`;
  const fixedOddsToProfit = `${parseFloat(oddsToProfitPercentage).toFixed(2)}%`;

  return (
    <>
      <Accordion.Item className="tradeSummaryCont" eventKey={id}>
        <Accordion.Header className="tradeSummaryHeader" as="div">
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">AVERAGE FLOAT</p>
            <p>{fixedAvgFloat}</p>
          </div>
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">TRADE UP COST</p>
            <p>{fixedtradeupCost}</p>
          </div>
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">TOP RESULT</p>
            <p>{`$${topResultPrice}`}</p>
          </div>
          <div className="tradeSummaryHeaderItem">
            <p className="tradeSummaryHeaderItemTitle">ODDS TO PROFIT</p>
            <p>{fixedOddsToProfit}</p>
          </div>
          {isShowDelete && (
            <div className="tradeSummaryHeaderItem tradeSummaryHeaderDeleteIcon">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => deleteTradeFromList(id)}
              />
            </div>
          )}
        </Accordion.Header>
        <Accordion.Body className="tradeSummaryAllTrades">
          <div className="tradeSkinsCont">
            <h3 className="tradeSkinsTitle">INPUTS</h3>
            <div className="tradeSkinsList">{renderTradeSkins(inputSkins)}</div>
          </div>
          <div className="tradeSkinsCont">
            <h3 className="tradeSkinsTitle tradesSkinOutcomesTitle">
              OUTCOMES
            </h3>
            <div className="tradeSkinsList">
              {renderTradeSkins(outputSkins)}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default TradeSummary;

/* Saving trades - deprecated

  isAllowSave = true,


  const [isSaved, setIsSaved] = useState(false);
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);

  const saveGeneratedTrade = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/saveGeneratedTradeData`, {
        id,
      })
      .then(() => {
        setIsSaved(true);
      })
      .catch((e) => {
        console.log("Error saving generated trade", e);
        setIsShowErrorToast(true);
      });
  };


{isAllowSave && (
  <Button
    variant="secondary"
    className="tradeCollectionSelectionBtn"
  >
    {isSaved ? (
      <span>
        Saved{" "}
        <FontAwesomeIcon
          className="savedButtonIcon"
          icon={faCircleCheck}
        />
      </span>
    ) : (
      <span
        onClick={() => {
          saveGeneratedTrade();
        }}
      >
        Save{" "}
        <FontAwesomeIcon
          className="savedButtonIcon"
          icon={faFloppyDisk}
        />
      </span>
    )}
  </Button>
)}


<ErrorToast
        errorMessage={"Error saving trade to profile"}
        isOpen={isShowErrorToast}
        closeToast={() => setIsShowErrorToast(false)}
      />
*/
