import React, { useState, useEffect } from "react";
import { ButtonGroup, Button } from "react-bootstrap";

import { CONDITION_SCALE_RANGES } from "../utils/constants.utils";
import { calculateLinePosition } from "../utils/ConditionScale.utils";

import "../styles/conditionScale.scss";

function StaticConditionScale({ floatVal }) {
  // adjust skin values for display
  const fixedFloatVal = (Math.trunc(floatVal * 1000) / 1000).toFixed(3); //float val beneath scale, truncate at 3rd decimal without rounding

  const [currentCondition, setCurrentCondition] = useState("");
  const [floatValPosition, setFloatValPosition] = useState("");

  useEffect(() => {
    // set initial condition based on the float value
    for (const [condition, floatBounds] of Object.entries(
      CONDITION_SCALE_RANGES
    )) {
      const min = floatBounds.min,
        max = floatBounds.max;
      if (floatVal >= min && floatVal <= max) {
        setCurrentCondition(condition);
        break;
      }
    }
    const initialFloatValPosition = calculateLinePosition(floatVal);
    setFloatValPosition(initialFloatValPosition);
  }, [floatVal]);

  return (
    <div className="conditionScaleCont staticConditionScale">
      <div className="scaleConditions">
        <ButtonGroup className="scaleConditionsBtnGroup">
          <Button
            className={`scaleSection ${
              currentCondition === "FN" ? "selectedConditionBg" : ""
            }`}
          >
            FN
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "MW" ? "selectedConditionBg" : ""
            }`}
          >
            MW
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "FT" ? "selectedConditionBg" : ""
            }`}
          >
            FT
          </Button>
          <Button
            className={`scaleSection ${
              currentCondition === "WW" ? "selectedConditionBg" : ""
            }`}
          >
            WW
          </Button>
          <div
            className="conditionScaleLine currentValLine"
            style={{ left: floatValPosition }}
          ></div>
          <Button
            className={`scaleSection ${
              currentCondition === "BS" ? "selectedConditionBg" : ""
            }`}
          >
            BS
          </Button>
        </ButtonGroup>

        <div className="rangeText">
          <div className="valueLabels">
            <span
              className="valueLabelStyle currentValLabel"
              style={{ left: floatValPosition }}
            >
              {fixedFloatVal}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaticConditionScale;
