import React, { useState, useEffect } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import StaticConditionScale from "./StaticConditionScale";

import "../styles/skinComponents.scss";

function Skin({ skinDetails, isTopResult }) {
  const {
    img: image_url,
    market_name: name,
    collection,
    item_float: floatVal,
    price,
    probability,
    marketplace,
    link_for_item,
  } = skinDetails;

  const [isShowProbability, setIsShowProbability] = useState(false);
  useEffect(() => {
    if (probability !== undefined) {
      setIsShowProbability(true);
    }
  }, [probability]);

  const fixedFloatVal = parseFloat(floatVal).toFixed(5);
  const probabilityPercentage = parseFloat(probability) * 100;
  const fixedProfitability = probabilityPercentage.toFixed(2);

  return (
    <a href={link_for_item} target="_blank" rel="noreferrer">
      <div
        className={`skinCont skinImgCard outcomeSkin ${
          isTopResult ? "skinTopResult" : ""
        }`}
      >
        <div className="skinCardHeader">
          <OverlayTrigger overlay={<Tooltip>{collection}</Tooltip>}>
            <p className="skinCardHeaderCollection">{collection}</p>
          </OverlayTrigger>
          <p className="skinCardSaticFloatDisplay">{fixedFloatVal}</p>
        </div>
        <img className="skinCardImg" src={image_url} alt={name} />
        <p className="skinCardTitle">{name}</p>
        <div className="skinCardConditionScale">
          <StaticConditionScale floatVal={floatVal} />
        </div>
        <div className="skinSelectedValues">
          {price === "0" ? "No recent price" : `$${price}`}
          {isShowProbability && "-"}
          {isShowProbability && `${fixedProfitability}%`}
          {marketplace && ` ${marketplace}`}
        </div>
      </div>
    </a>
  );
}

export default Skin;
