import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Col,
  Row,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Accordion,
  Modal,
} from "react-bootstrap";

import TradeSummary from "../../components/TradeSummary";

import { RARITIES } from "../../utils/constants.utils";
import { isUserLoggedIn } from "../../utils/General.utils";

import "../../styles/tradeUp.scss";

const TradeupProSimplified = () => {
  const [selectedRarity, setSelectedRarity] = useState("MS");
  const [isStatTrak, setisStatTrak] = useState(true);

  const [allTrades, setAllTrades] = useState([]);

  const [isShowLoginModal, setIsShowLoginModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserLoggedIn()) {
      setIsShowLoginModal(true);
    }
  }, []);

  useEffect(() => {
    // Reset trade details in case of rarity change
    setAllTrades([]);
  }, [selectedRarity, isStatTrak]);

  const updateSelectedRarity = (eventKey) => {
    Object.keys(RARITIES).forEach((rarity) => {
      if (eventKey === rarity) {
        setSelectedRarity(eventKey);
        return;
      }
    });
  };

  const generateTradesFromRarity = () => {
    if (!isUserLoggedIn()) {
      if (!isUserLoggedIn()) {
        return setIsShowLoginModal(true);
      }
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/tradesByRarity`, {
        rarity: RARITIES[selectedRarity],
      })
      .then((res) => {
        const newTradesData = res.data;
        setAllTrades([...newTradesData, ...allTrades]);
      })
      .catch((e) => console.log("Error fetching trades by rarity", e));
  };

  const deleteTradeFromList = (tradeId) => {
    const filteredTrades = allTrades.filter((trade) => {
      return trade.id !== tradeId;
    });

    setAllTrades(filteredTrades);
  };

  const renderAllTrades = () => {
    return allTrades.map((trade) => {
      return (
        <TradeSummary
          trade={trade}
          isShowDelete={true}
          deleteTradeFromList={deleteTradeFromList}
          key={trade.id}
        />
      );
    });
  };

  return (
    <div className="tradeUpCont">
      <Row className="tradeSelectionCont" md="12">
        <Col className="descColumnTitleCont" md="4" sm="12">
          <h1>Trade up</h1>
          <p>
            Automatically get odds to discover CS:GO's most profitable trade
            ups. Our cutting edge algorithm finds the best skin combinations on
            the market. Choose a rarity to trade with below.
          </p>
          <div className="rarityFilterToggles">
            <div className="raritySelectorCont">
              <div className="raritySelectorLabel">Select a rarity</div>
              <DropdownButton
                as={ButtonGroup}
                onSelect={(eventKey) => {
                  updateSelectedRarity(eventKey);
                }}
                variant="outline-primary"
                title={RARITIES[selectedRarity]}
                className="rarityDropdownCont"
              >
                {isStatTrak ? null : (
                  <Dropdown.Item
                    eventKey="CG"
                    id="dropdownItemCG"
                    active={selectedRarity === "CG"}
                  >
                    Consumer Grade
                  </Dropdown.Item>
                )}
                {isStatTrak ? null : (
                  <Dropdown.Item
                    eventKey="IG"
                    id="dropdownItemIG"
                    active={selectedRarity === "IG"}
                  >
                    Industrial Grade
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  eventKey="MS"
                  id="dropdownItemMS"
                  active={selectedRarity === "MS"}
                >
                  Mil-Spec
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="R"
                  id="dropdownItemR"
                  active={selectedRarity === "R"}
                >
                  Restricted
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="C"
                  id="dropdownItemC"
                  active={selectedRarity === "C"}
                >
                  Classified
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <Button
              // this state is deprecated while the system only supports stattrak
              onClick={() => {
                setisStatTrak((prevState) => !prevState);
              }}
              variant="outline-primary"
              className={`statTrakBtn ${isStatTrak ? "statTrakBtnActive" : ""}`}
            >
              Stat Trak™
            </Button>
          </div>
          <div className="generateTradeBtnCont">
            <Button
              className="generateTradeBtn"
              onClick={() => generateTradesFromRarity()}
              size="lg"
              variant="secondary"
            >
              Generate Trades
            </Button>
            <p className="tradeAvailabilityDisclaimer">
              Some trades may no longer be available, the market moves fast!
            </p>
          </div>
        </Col>
        <Col className="tradeSummariesColumnCont" md="8">
          <Accordion className="tuAccordionWrapper tradeCollectionAccordion">
            {renderAllTrades()}
          </Accordion>
        </Col>
      </Row>

      <Modal
        show={isShowLoginModal}
        onHide={() => setIsShowLoginModal(false)}
        centered
        className="modalCont"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Login or create an account to generate profitable trade ups</h3>
          <Button variant="outline-primary" onClick={() => navigate("/login")}>
            Go to Login
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TradeupProSimplified;
